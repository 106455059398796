var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("div", { staticClass: "text-center f16" }, [
        _vm._v(
          _vm._s(_vm.fullExam ? _vm.$t("Exam") + " " + _vm.fullExam.name : "")
        ),
      ]),
      _c("v-progress-linear", {
        attrs: {
          active: _vm.loadingBarState,
          indeterminate: _vm.loadingBarState,
          absolute: "",
          bottom: "",
          color: "light-blue accent-4",
        },
      }),
      _c(
        "div",
        { staticClass: "d-flex flex-column justify-content-center" },
        [
          _vm.fullExam
            ? _c(
                "div",
                { staticClass: "d-flex flex-column justify-content-center" },
                [
                  _c("div", { staticClass: "pa-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("Number of questions:")) +
                        " " +
                        _vm._s(_vm.fullExam.questions.length) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "span",
                                  { staticClass: "f14 text-justify" },
                                  [_vm._v(_vm._s(_vm.$t("Exam description")))]
                                ),
                              ]),
                              _c("v-expansion-panel-content", [
                                _c("p", {
                                  staticStyle: { "text-align": "justify" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.fullExam.drDescription
                                    ),
                                  },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-expansion-panels",
                        { attrs: { focusable: "" } },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _c(
                                  "span",
                                  { staticClass: "f14 text-justify" },
                                  [_vm._v(_vm._s(_vm.$t("Questions Preview")))]
                                ),
                              ]),
                              _c("v-expansion-panel-content", [
                                _c(
                                  "div",
                                  { staticClass: "pa-2" },
                                  [
                                    _c(
                                      "v-expansion-panels",
                                      _vm._l(
                                        _vm.fullExam.questions,
                                        function (question) {
                                          return _c(
                                            "v-expansion-panel",
                                            { key: question.id },
                                            [
                                              _c("v-expansion-panel-header", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "f13 text-justify",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(question.number) +
                                                        ". " +
                                                        _vm._s(question.title)
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _c("v-expansion-panel-content", [
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          question.type ===
                                                          "NORMAL",
                                                        expression:
                                                          "question.type === 'NORMAL'",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c(
                                                      "ol",
                                                      _vm._l(
                                                        question.items,
                                                        function (item) {
                                                          return _c(
                                                            "li",
                                                            {
                                                              key: item.id,
                                                              staticClass:
                                                                "text-lg-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          question.type ===
                                                          "LIKERT",
                                                        expression:
                                                          "question.type === 'LIKERT'",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _c("v-slider", {
                                                      staticClass: "mt-8",
                                                      attrs: {
                                                        "thumb-size": 24,
                                                        "thumb-label": "always",
                                                        ticks: "always",
                                                        "tick-size": "8",
                                                        dense: "",
                                                        "hide-details": "",
                                                        min: question.likertItem
                                                          ? question.likertItem
                                                              .startFrom
                                                          : undefined,
                                                        max: question.likertItem
                                                          ? question.likertItem
                                                              .endTo
                                                          : undefined,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          !_vm.fullExam && !_vm.loadingBarState
            ? _c("v-flex", { staticClass: "text-center" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("YOU DON'T HAVE PERMISSION...")) + " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }